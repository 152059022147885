export type TableSettingsColumn = {
  present: boolean;
  index: number;
  disabled?: boolean;
};

export type TableSettingsColumnWithId = TableSettingsColumn & {
  id: string;
  label: string;
};

export type TableSettingsColumns = {
  [id: string]: TableSettingsColumn;
};

export type TableSettings = {
  columns: TableSettingsColumns;
  page_size?: number;
};

export class Settings {
  listing_table: TableSettings;
  pricing_activity_table: TableSettings;

  constructor(settings: any) {
    if (!settings) {
      throw new Error("error constructing user settings");
    }
    this.listing_table = settings?.listing_table;
    this.pricing_activity_table = settings?.pricing_activity_table;
  }
}
