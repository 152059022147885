export class PlanUsage {
  accountId?: string;
  activeListings: number;
  hyperdriveListings: number;
  aiListings: number;
  repricingListings: number;
  strategies: number;
  aiStrategies: number;
  marketplaces: number;
  users: number;
  workflows: number;
  ilIntegrationEnabled: boolean;
  scanPowerIntegrationEnabled: boolean;
  restockProIntegrationEnabled: boolean;
  boxemIntegrationEnabled: boolean;
  last30DaysSales: number;
  betaCouponAmountOff: number | null;
  betaCouponPercentOff: number | null;
  betaCouponDurationInMonths: number | null;

  constructor(usage: any) {
    if (!usage) {
      throw new Error("error constructing usage");
    }
    this.activeListings = usage.active_listings;
    this.hyperdriveListings = usage.hyperdrive_listings;
    this.aiListings = usage.ai_listings;
    this.repricingListings = usage.repricing_listings;
    this.strategies = usage.strategies;
    this.aiStrategies = usage.ai_strategies;
    this.marketplaces = usage.marketplaces;
    this.users = usage.users;
    this.workflows = usage.workflows;
    this.ilIntegrationEnabled = usage.il_integration_enabled;
    this.scanPowerIntegrationEnabled = usage.scanpower_integration_enabled;
    this.restockProIntegrationEnabled = usage.restockpro_integration_enabled;
    this.boxemIntegrationEnabled = usage.boxem_integration_enabled;
    this.last30DaysSales = usage.last_30_days_sales;
    this.betaCouponAmountOff = usage.beta_coupon_amount_off ?? null;
    this.betaCouponPercentOff = usage.beta_coupon_percent_off ?? null;
    this.betaCouponDurationInMonths =
      usage.beta_coupon_duration_in_months ?? null;
  }
}
