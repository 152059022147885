// include styles from the ui packages
import "legacy-ui/styles.css";
import "@goaura/aspect/styles.css";
import "@/styles/globals.css";
import "@/styles/nprogress.css";
import "flag-icons/css/flag-icons.min.css";
import type { AppProps } from "next/app";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import type { NextPage } from "next";
import { ThemeProvider } from "next-themes";
import Script from "next/script";
import Head from "next/head";
import localFont from "next/font/local";
import { DefaultSeo } from "next-seo";
import NProgress from "nprogress";
import { useRouter } from "next/router";
import NextAdapterPages from "next-query-params/pages";
import { QueryParamProvider } from "use-query-params";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { Analytics } from "@vercel/analytics/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { BreadcrumbProvider } from "@/lib/hooks/breadcrumbs";
import { AnalyticsProvider } from "@/lib/hooks/analytics";
import { AuthProvider } from "@/lib/hooks/auth";
// import { SpeedInsights } from '@vercel/speed-insights/next';

const aeonik = localFont({
  variable: "--font-aeonik",
  display: "swap",
  src: [
    {
      path: "../../public/fonts/Aeonik/Aeonik-Black.woff2",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-Bold.woff2",
      weight: "bold",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-Regular.woff2",
      weight: "normal",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-Medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-Light.woff2",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-Thin.woff2",
      weight: "100",
      style: "normal",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-BlackItalic.woff2",
      weight: "900",
      style: "italic",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-BoldItalic.woff2",
      weight: "bold",
      style: "italic",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-RegularItalic.woff2",
      weight: "normal",
      style: "italic",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-MediumItalic.woff2",
      weight: "500",
      style: "italic",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-LightItalic.woff2",
      weight: "300",
      style: "italic",
    },
    {
      path: "../../public/fonts/Aeonik/Aeonik-ThinItalic.woff2",
      weight: "100",
      style: "italic",
    },
  ],
});

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  theme?: string;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  const router = useRouter();
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const handleRouteStart = () => NProgress.start();
    const handleRouteDone = () => NProgress.done();

    router.events.on("routeChangeStart", handleRouteStart);
    router.events.on("routeChangeComplete", handleRouteDone);
    router.events.on("routeChangeError", handleRouteDone);

    return () => {
      router.events.off("routeChangeStart", handleRouteStart);
      router.events.off("routeChangeComplete", handleRouteDone);
      router.events.off("routeChangeError", handleRouteDone);
    };
  }, [router]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover"
        />
      </Head>

      <Script id="load-partnerstack">
        {`(function() {var gs = document.createElement('script');gs.src = 'https://try.goaura.com/pr/js';gs.type = 'text/javascript';gs.async = 'true';gs.onload = gs.onreadystatechange = function() {var rs = this.readyState;if (rs && rs != 'complete' && rs != 'loaded') return;try {growsumo._initialize('pk_0spiUroVnQ4ocAKLnLMdhydehYPJ7GhM'); if (typeof(growsumoInit) === 'function') {growsumoInit();}} catch (e) {}};var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(gs, s);})();`}
      </Script>

      <Script src="https://script.tapfiliate.com/tapfiliate.js" />
      <Script id="load-tapfiliate">
        {`(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
        (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

        tap('create', '8928-be872a', { integration: "stripe" });
        tap('detect');`}
      </Script>

      <Script id="load-canny">
        {`!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");

        Canny('initChangelog', {
          appID: '61e1de980b84dc0ff13a2fdc',
          position: 'bottom',
          align: 'right',
          theme: 'light',
        });`}
      </Script>

      <DefaultSeo
        title="Aura | The Modern Amazon Repricer"
        description="The modern Amazon repricer, powered by AI."
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/favicon.svg",
          },
        ]}
      />

      <style jsx global>{`
        :root {
          --font-aeonik: ${aeonik.style.fontFamily};
        }
      `}</style>

      <ThemeProvider
        attribute="data-theme"
        defaultTheme="light"
        forcedTheme={Component.theme}
      >
        <LDProvider
          clientSideID={
            process.env.NODE_ENV === "development"
              ? "6798ca7195f08509b8d259f7"
              : "6798ca7195f08509b8d259f8"
          }
          reactOptions={{ useCamelCaseFlagKeys: false }}
        >
          <QueryClientProvider client={queryClient}>
            <BreadcrumbProvider>
              <AnalyticsProvider>
                <TooltipPrimitive.Provider delayDuration={0}>
                  <AuthProvider>
                    <QueryParamProvider adapter={NextAdapterPages}>
                      {getLayout(<Component {...pageProps} />)}
                      {/* <SpeedInsights /> */}
                    </QueryParamProvider>
                  </AuthProvider>
                </TooltipPrimitive.Provider>
              </AnalyticsProvider>
            </BreadcrumbProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-left"
            />
          </QueryClientProvider>
        </LDProvider>
      </ThemeProvider>
      <Analytics />
    </>
  );
}

export default MyApp;
